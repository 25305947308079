<template>
   <div class="content-view pagamento-completato pt-5 text-center">
      
      <div class="wrap mb-5">
         <h1 class="mb-4">Grazie per aver acquistato</h1>

         <div>
            <i class="far fa-check-circle text-primary" style="font-size:100px"></i>
         </div>
      </div>

      <router-link 
         :to="'/'"
         class="btn btn-secondary radius-20">
         Torna alla home
      </router-link>

   </div>
</template>

<script scoped>
export default {
   name: "PagamentoCompletato",
}
</script>
<style scoped>
.wrap{
   max-width: 300px;
   margin: 0 auto;
}
</style>